import React, { useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline } from '@sumup/circuit-ui';
import scrollIntoView from 'scroll-into-view';

import useIsMobile from '~/shared/hooks/use-is-mobile';
import { ALIGNMENT, COLORS } from '~/shared/constants';
import formatSelector from '~/shared/util/data-selector';
import { richTextPropType } from '~/shared/util/shared-prop-types';
import CrossIcon from '~/shared/components/icons/CrossIcon';
import RichText from '~/shared/components/RichText';
import * as expansionPanelContent from '~/shared/components/RichText/configs/expansionPanel-content';
import useComponentSize from '~/shared/hooks/use-component-size';

const SPACE_KEY_CODE = 32;
const SCROLL_TOP_OFFSET = 75;

const dtStyles = ({ theme, isOpen, alignment, colorActive }) => css`
  // align headline text and cross icon
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${colorActive === COLORS.BLUE &&
  isOpen &&
  `
    background-color: var(--cui-bg-subtle);
  `}

  // should be full border when content is close
  ${!isOpen &&
  `
    border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    border-radius: ${theme.borderRadius.tera};
    padding: ${theme.spacings.mega} ${theme.spacings.giga};

    ${theme.mq.untilKilo} {
      padding: ${theme.spacings.kilo} ${theme.spacings.mega};
    }
  `}

  // should show half upper border when content is open
  ${isOpen &&
  `
    border-top: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    border-right: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    border-left: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    border-top-right-radius: ${theme.borderRadius.tera};
    border-top-left-radius: ${theme.borderRadius.tera};
    padding: ${theme.spacings.mega} ${theme.spacings.giga};

    outline: 0;

    ${theme.mq.untilKilo} {
      padding: ${theme.spacings.kilo} ${theme.spacings.mega};
    }
  `}

  ${alignment === ALIGNMENT.LEFT &&
  `
    flex-direction: row-reverse;
    justify-content: flex-end;
  `}

  transition: all ${theme.transitions.default};

  outline-color: var(--cui-fg-accent);
`;
const Dt = styled('dt')(dtStyles);

const headlineStyles = ({ theme, colorActive, alignment, isOpen }) => css`
  flex-grow: 0;
  margin-right: ${theme.spacings.kilo};
  margin-bottom: 0;
  width: 100%;

  ${colorActive === COLORS.BLUE &&
  isOpen &&
  `
    color: var(--cui-fg-accent);
  `}

  ${alignment === ALIGNMENT.LEFT &&
  `
    margin-left: ${theme.spacings.kilo};
    margin-right: 0;
  `}

  ${theme.mq.untilKilo} {
    ${theme.typography.headline.four};
  }
`;

const StyledHeadline = styled(Headline)(headlineStyles);

const collapseStyles = ({ theme, isOpen, contentHeight, colorActive }) => css`
  // spacing between expansion panels
  &:not(:last-child) {
    margin-bottom: ${theme.spacings.byte};
  }

  border-right: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
  border-left: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
  border-bottom-right-radius: ${theme.borderRadius.tera};
  border-bottom-left-radius: ${theme.borderRadius.tera};

  padding-right: ${theme.spacings.giga};
  padding-left: ${theme.spacings.giga};

  ${theme.mq.untilKilo} {
    padding-right: ${theme.spacings.mega};
    padding-left: ${theme.spacings.mega};
  }

  // should be hidden when content is close
  ${!isOpen &&
  `
    max-height: 0;
    visibility: hidden;
  `}

  // should show half lower border when content is open
  // A valid contentHeight should be greater than 0
  ${isOpen &&
  contentHeight > 0 &&
  `
    // content height + headline padding-bottom
    max-height: calc(${contentHeight}px + ${theme.spacings.mega});

    border-bottom: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
    visibility: visible;

    ${theme.mq.untilKilo} {
      max-height: calc(${contentHeight}px + ${theme.spacings.kilo});
    }
  `}

  ${colorActive === COLORS.BLUE &&
  isOpen &&
  `
    background-color: var(--cui-bg-subtle);
  `}

  // collapse animation
  transition: max-height ${theme.transitions.default}, visibility ${theme
    .transitions.default};
  overflow: hidden;
`;
const CollapseDD = styled('dd')(collapseStyles);

/**
 * A panel with fixed headline and expandable content
 */
function ExpansionPanel({
  headline,
  content,
  contentType,
  name,
  id,
  as,
  headingElement,
  onOpen = () => {},
  onClose = () => {},
  isOpen = false,
  headingAlignment = ALIGNMENT.LEFT,
  headingColorActive,
  children,
}) {
  const contentEl = useRef(null);
  const headingEl = useRef(null);

  const contentSize = useComponentSize(contentEl);
  const isMobile = useIsMobile();

  if (!headline && !content) {
    return null;
  }

  const toggleContent = () => {
    if (!isOpen) {
      onOpen();
      if (isMobile) {
        scrollIntoView(headingEl.current, {
          align: {
            top: 0,
            topOffset: SCROLL_TOP_OFFSET,
          },
        });
      }
      return;
    }

    onClose();
  };

  const toggleContentWithKey = (event) => {
    if (event.code === SPACE_KEY_CODE || event.keyCode === SPACE_KEY_CODE) {
      event.preventDefault();
      toggleContent();
    }
  };

  return (
    <Fragment>
      <Dt
        isOpen={isOpen}
        ref={headingEl}
        colorActive={headingColorActive}
        onClick={toggleContent}
        onKeyDown={toggleContentWithKey}
        tabIndex={0}
        aria-expanded={isOpen}
        data-selector={formatSelector('headline', 'expansionPanel')}
        alignment={headingAlignment}
      >
        <StyledHeadline
          alignment={headingAlignment}
          colorActive={headingColorActive}
          as={as}
          size="four"
          isOpen={isOpen}
        >
          {headline}
        </StyledHeadline>
        <CrossIcon
          direction={!isOpen ? CrossIcon.PLUS_SIGN : CrossIcon.X_MARK}
          isActive={isOpen && headingColorActive === COLORS.BLUE}
        />
      </Dt>
      <CollapseDD
        isOpen={isOpen}
        contentHeight={contentSize.height}
        aria-hidden={!isOpen}
        colorActive={headingColorActive}
        data-selector={formatSelector('collapse', 'expansionPanel')}
      >
        <div ref={contentEl}>
          {headingElement}
          <RichText
            richText={content}
            renderNode={expansionPanelContent.createRenderNode(
              {
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              },
              isOpen,
            )}
            renderMark={expansionPanelContent.renderMark}
          />
          {children}
        </div>
      </CollapseDD>
    </Fragment>
  );
}

ExpansionPanel.propTypes = {
  /**
   * A concise headline. For example: Title for FAQ section (supports RichText).
   */
  headline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * A concise content. For example: Answer for FAQ section.
   */
  content: richTextPropType,
  /**
   * The trackingId for tracking opening and closing this section.
   */
  trackingId: PropTypes.string,
  /**
   * The click events are sent to Optimizely Full Stack for tracking opening and closing this section.
   */
  optimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  as: PropTypes.string,
  headingElement: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  headingAlignment: PropTypes.string,
  headingColorActive: PropTypes.string,
  children: PropTypes.object,
};

/**
 * @component
 */
export default ExpansionPanel;
