import React, { useState } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import PropTypes from 'prop-types';
import { Grid } from '@sumup/circuit-ui/legacy';
import { elb } from '@elbwalker/walker.js';

import ExpansionPanel from './components/ExpansionPanel';
import * as ExnapsionPanelListService from './ExpansionPanelListService';

import formatSelector from '~/shared/util/data-selector';
import SectionHeader from '~/shared/components/SectionHeader';
import { ALIGNMENT, COLORS } from '~/shared/constants';
import { richTextPropType } from '~/shared/util/shared-prop-types';
import * as Analytics from '~/shared/services/analytics';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

/**
 * A section consists of a headline, a description and a list of expansion panels.
 */
function ExpansionPanelList({
  headline,
  description,
  alignment = ALIGNMENT.LEFT,
  expansionPanels: originalExpansionPanels = [],
  className,
  index,
  onlyOne = false,
  openPanelId = null,
  onOpen = () => {},
  onClose = () => {},
  headingAlignment = ALIGNMENT.LEFT,
  headingColorActive = COLORS.BLACK,
}) {
  const initialOpenPanels = openPanelId !== null ? [openPanelId] : [];
  const [openPanels, setOpenPanels] = useState(initialOpenPanels);
  const { experiments } = useOptimizelyData();
  const expansionPanels = mapVariations(originalExpansionPanels, experiments);

  if (isEmpty(expansionPanels)) {
    return null;
  }

  const as = ExnapsionPanelListService.getHTag(index, headline);

  const onOpenPanel = (id, panel = {}) => {
    if (!openPanels.includes(id)) {
      const updatedOpenPanels = onlyOne ? [id] : [...openPanels, id];

      setOpenPanels(updatedOpenPanels);
      onOpen(panel);

      elb('button clicked', {
        button_description: 'open@faq',
      });

      Analytics.sendEvent({
        event: 'interaction',
        target: 'Mkt_Web',
        action: panel.trackingId,
        contentType: panel.contentType,
        contentEntryName: panel.name,
        contentEntryId: panel.id,
        destinationCategory: Analytics.destinationCategory.UI_OPERATION,
        destinationUrl: undefined,
      });
      sendNinetailedEvent(panel.trackingId);

      OptimizelyFullStack.trackEvents(panel.optimizelyFullStackClickEvents);
    }
  };

  const onClosePanel = (id, item) => {
    if (openPanels.includes(id)) {
      const updatedOpenPanels = openPanels.filter((p) => p !== id);

      setOpenPanels(updatedOpenPanels);
      onClose(item);
    }
  };

  return (
    <Grid
      className={className}
      data-selector={formatSelector('section', 'expansionPanelList')}
      data-elbcontext="component:expansion_panel_list"
    >
      <SectionHeader
        headline={headline}
        content={description}
        alignment={alignment}
        index={index}
      />
      <dl>
        {expansionPanels.map((expansionPanel, idx) => (
          <ExpansionPanel
            key={`${expansionPanel.headline}${idx}`}
            as={as}
            isOpen={openPanels.includes(idx)}
            onOpen={() => onOpenPanel(idx, expansionPanel)}
            onClose={() => onClosePanel(idx, expansionPanel)}
            headingColorActive={headingColorActive}
            headingAlignment={headingAlignment}
            {...expansionPanel}
          />
        ))}
      </dl>
    </Grid>
  );
}

ExpansionPanelList.propTypes = {
  className: PropTypes.string,
  /**
   * A concise headline for the list.
   */
  headline: PropTypes.string,
  /**
   * A concise description about the list.
   */
  description: richTextPropType,
  /**
   * An aliment for the headline and description.
   */
  alignment: PropTypes.oneOf([ALIGNMENT.CENTER, ALIGNMENT.LEFT]),
  /**
   * Show only one open panel at the time
   */
  onlyOne: PropTypes.bool,
  /**
   * A array of expansion panels. Please refers to expansion panel component for more details.
   */
  expansionPanels: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  /**
   * Panel that should be open by default (id is by index, starts at 0)
   */
  openPanelId: PropTypes.number,
  /**
   * Triggers when a panel opens
   */
  onOpen: PropTypes.func,
  /**
   * Triggers when panel closes
   */
  onClose: PropTypes.func,
  /**
   * Alignment of panel headings
   */
  headingAlignment: PropTypes.oneOf([ALIGNMENT.RIGHT, ALIGNMENT.LEFT]),
  /**
   * Color of panel headings
   */
  headingColorActive: PropTypes.oneOf([COLORS.BLUE, COLORS.BLACK]),
};

ExpansionPanelList.RIGHT = ALIGNMENT.RIGHT;
ExpansionPanelList.CENTER = ALIGNMENT.CENTER;
ExpansionPanelList.LEFT = ALIGNMENT.LEFT;

ExpansionPanelList.BLUE = COLORS.BLUE;
ExpansionPanelList.BLACK = COLORS.BLACK;
/*
 * @component
 */
export default ExpansionPanelList;
