import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Cross from './svg/cross.svg';

const PLUS_SIGN = 'plus_sign';
const X_MARK = 'x_mark';

const rotateMap = {
  [PLUS_SIGN]: '0deg',
  [X_MARK]: '-45deg',
};

const baseStyles = ({ theme, direction, isActive }) => css`
  label: cross-icon;
  flex-shrink: 0;
  transform: rotate(${rotateMap[direction]});
  transition: transform ${theme.transitions.default};

  & path {
    fill: ${isActive ? 'var(--cui-fg-accent)' : 'var(--cui-fg-subtle)'};
  }
`;

const CrossIcon = styled(Cross, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(baseStyles);

CrossIcon.propTypes = {
  /**
   * Plus sign: +, X mark: x
   */
  direction: PropTypes.oneOf([PLUS_SIGN, X_MARK]),
  isActive: PropTypes.bool,
};

CrossIcon.defaultProps = {
  direction: PLUS_SIGN,
};

CrossIcon.PLUS_SIGN = PLUS_SIGN;
CrossIcon.X_MARK = X_MARK;

/**
 * @component
 */
export default CrossIcon;
