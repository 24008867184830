import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, List, Hr } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { MergeTag } from '@ninetailed/experience.js-next';

import EmbeddedVariable from '../components/EmbeddedVariable';
import { getFields, getContentType } from '../util';

import Anchor from '~/shared/components/Anchor';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';

const StyledBodyBold = styled(Body)(css`
  font-size: inherit !important;
`);

const StyledBodyItalic = styled(Body)(css`
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: italic;
`);

const StyledList = styled(List)(({ theme, size }) => {
  const sizeMap = {
    one: theme.spacings.byte,
    two: theme.spacings.kilo,
  };

  return css`
    margin-bottom: ${theme.spacings.mega};

    li:last-child,
    ul:last-child,
    ol:last-child {
      margin-bottom: ${sizeMap[size]};
    }
  `;
});

export function createRenderNode(trackingContentEntry, isOpen) {
  return {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Body size="one" css={spacing({ bottom: 'mega' })}>
        {children}
      </Body>
    ),
    [BLOCKS.UL_LIST]: (node, children) => (
      <StyledList size="one">{children}</StyledList>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <StyledList size="one" variant="ordered">
        {children}
      </StyledList>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <Body variant="quote" size="one" css={spacing({ bottom: 'mega' })}>
        {children}
      </Body>
    ),
    [BLOCKS.HR]: () => <Hr />,
    [INLINES.HYPERLINK]: (node, children) => (
      <Anchor
        href={node.data.uri}
        trackingContentEntry={trackingContentEntry}
        tabIndex={!isOpen ? '-1' : null}
      >
        {children}
      </Anchor>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const contentType = getContentType(node);
      const fields = getFields(node);

      if (
        node?.data?.target?.contentType === 'nt_mergetag' &&
        node?.data?.target?.nt_mergetag_id &&
        NINETAILED_IS_ENABLED
      ) {
        return <MergeTag id={node.data.target.nt_mergetag_id} />;
      }

      switch (contentType) {
        case 'embeddedVariable':
          return <EmbeddedVariable {...fields} />;
        default:
          return null;
      }
    },
  };
}

export const renderMark = {
  [MARKS.BOLD]: (text) => (
    <StyledBodyBold
      as="strong"
      size="one"
      variant="highlight"
      css={spacing({ bottom: 'mega' })}
    >
      {text}
    </StyledBodyBold>
  ),
  [MARKS.ITALIC]: (text) => (
    <StyledBodyItalic as="em" size="one" css={spacing({ bottom: 'mega' })}>
      {text}
    </StyledBodyItalic>
  ),
};
