export const getHTag = (index, headline) => {
  const isFirst = index === 0;
  const hasHeadline = !!headline;

  if (isFirst && hasHeadline) {
    return 'h2';
  }

  if (!isFirst && hasHeadline) {
    return 'h3';
  }

  return 'div';
};
